<template>
  <div
    class="flex flex-col justify-center items-center rounded-md border-solid border-2 border-primary py-2 px-2 mx-auto w-10/12"
  >
    <template v-if="profileIsComplete">
      <div
        class="flex flex-row justify-between items-center w-full px-4 space-x-4"
        v-show="!isCameraOpen"
      >
        <span>
          <button type="button" @click="toggleCamera()">
            <span class="flex flex-row items-center text-sm space-x-2">
              <img class="w-8 h-8 mr-1" src="@/assets/images/camera.png" alt />
              Take Photo
            </span>
            <!-- <span v-else>Close Camera</span> -->
          </button>
        </span>

        <div class="w-20 h-16 border-dashed border-2 border-black flex flex-row items-center">
          <div id="resultImage" class="w-16 h-12 mx-auto"></div>
        </div>
      </div>
    </template>
    <template v-else>
      Please, complete your profile:
      add sterilizer and
      select a clinic
    </template>
    <!-- spinner -->
    <div v-show="isCameraOpen && isLoading" class="camera-loading"></div>

    <div v-if="isCameraOpen" v-show="!isLoading" class="camera-box" :class="{ flash: isShotPhoto }">
      <div class="camera-shutter" :class="{ flash: isShotPhoto }"></div>

      <video v-show="!isPhotoTaken" ref="camera" :width="450" :height="337.5" autoplay></video>
      <canvas v-show="isPhotoTaken" id="photoTaken" ref="canvas" :width="450" :height="337.5"></canvas>
    </div>
    <div v-if="isCameraOpen && !isLoading" class="camera-shoot">
      <button type="button" class="button" @click="takePhoto()">
        <img src="@/assets/images/camera.png" alt />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "CameraInput",
  props: {
    profileIsComplete: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isCameraOpen: false,
      isPhotoTaken: false,
      isShotPhoto: false,
      isLoading: false,
      image: null
    };
  },
  methods: {
    toggleCamera() {
      if (this.isCameraOpen) {
        this.isCameraOpen = false;
        this.isPhotoTaken = false;
        this.isShotPhoto = false;
        this.stopCameraStream();
      } else {
        this.isCameraOpen = true;

        this.createCameraElement();
      }
    },

    createCameraElement() {
      this.isLoading = true;

      const constraints = (window.constraints = {
        audio: false,
        video: {
          facingMode: "environment"
        }
      });

      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(stream => {
          this.isLoading = false;
          this.$refs.camera.srcObject = stream;
        })
        .catch(error => {
          this.isLoading = false;
          console.error(error.message, error);
        });
    },

    stopCameraStream() {
      let tracks = this.$refs.camera.srcObject.getTracks();

      tracks.forEach(track => {
        track.stop();
      });

      this.$refs.camera.srcObject = null;
    },

    takePhoto() {
      if (!this.isPhotoTaken) {
        this.isShotPhoto = true;

        const FLASH_TIMEOUT = 50;

        setTimeout(() => {
          this.isShotPhoto = false;
        }, FLASH_TIMEOUT);
      }

      this.isPhotoTaken = !this.isPhotoTaken;

      const context = this.$refs.canvas.getContext("2d");
      context.drawImage(this.$refs.camera, 0, 0, 450, 337.5);

      this.image = document.getElementById("photoTaken").toDataURL("image/jpeg");

      var resultImage = new Image();
      resultImage.id = "pic";
      resultImage.src = document.getElementById("photoTaken").toDataURL("image/jpeg");
      document.getElementById("resultImage").appendChild(resultImage);

      this.isCameraOpen = false;
      this.stopCameraStream();

      this.$emit("imageUpdate", this.image);
    }

    // downloadImage() {
    //   const download = document.getElementById("downloadPhoto");
    //   const canvas = document
    //     .getElementById("photoTaken")
    //     .toDataURL("image/jpeg");
    //   download.setAttribute("href", canvas);
    // },
  }
};
</script>

<style lang="scss" scoped>
.web-camera-container {
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  // width: 500px;

  .camera-button {
    margin-bottom: 2rem;
  }

  .camera-box {
    .camera-shutter {
      opacity: 0;
      // width: 450px;
      // height: 337.5px;
      width: 274px;
      height: 154.125px;
      background-color: #fff;
      position: absolute;

      &.flash {
        opacity: 1;
      }
    }
  }

  .camera-shoot {
    margin: 1rem 0;

    button {
      height: 60px;
      width: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;

      img {
        height: 35px;
        object-fit: cover;
      }
    }
  }

  .camera-loading {
    overflow: hidden;
    height: 100%;
    position: absolute;
    width: 100%;
    min-height: 150px;
    margin: 3rem 0 0 -1.2rem;

    ul {
      height: 100%;
      position: absolute;
      width: 100%;
      z-index: 999999;
      margin: 0;
    }

    .loader-circle {
      display: block;
      height: 14px;
      margin: 0 auto;
      top: 50%;
      left: 100%;
      transform: translateY(-50%);
      position: absolute;
      width: 100%;
      padding: 0;

      li {
        display: block;
        float: left;
        width: 10px;
        height: 10px;
        line-height: 10px;
        padding: 0;
        position: relative;
        margin: 0 0 0 4px;
        background: #999;
        animation: preload 1s infinite;
        top: -50%;
        border-radius: 100%;

        &:nth-child(2) {
          animation-delay: 0.2s;
        }

        &:nth-child(3) {
          animation-delay: 0.4s;
        }
      }
    }
  }

  @keyframes preload {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.4;
    }
    100% {
      opacity: 1;
    }
  }
}
</style>
