import firebase from "firebase/app";
import "firebase/auth";

export const signUp = ({ commit }, payload) => {
  return firebase
    .auth()
    .createUserWithEmailAndPassword(payload.email, payload.password)
    .then((firebaseUser) => {
      commit("SET_USER", { email: firebaseUser.user.email, uid: firebaseUser.user.uid });

      localStorage.setItem("isNewUser", "true");

      commit("SET_USER_ERROR", null);
    })
    .catch((error) => commit("SET_USER_ERROR", error.message));
};

export const signIn = ({ commit }, payload) => {
  commit("SET_USER_ERROR", null);

  return firebase
    .auth()
    .signInWithEmailAndPassword(payload.email, payload.password)
    .then((firebaseUser) => {
      commit("SET_USER", { email: firebaseUser.user.email, uid: firebaseUser.user.uid });

      return firebaseUser;
    })
    .catch((error) => commit("SET_USER_ERROR", error.message));
};

export const signOut = ({ commit }) => {
  commit("SET_USER_ERROR", null);

  return firebase
    .auth()
    .signOut()
    .then(() => {
      commit("SET_USER", null);
      commit("audit/SET_AUDIT_DATA", null, { root: true });
      commit("auth/SET_CURRENT_USER_DATA", null, { root: true });
      commit("clinic/SET_CLINIC_DATA", null, { root: true });
      commit("results/SET_RESULTS_DATA", null, { root: true });
      commit("userData/CLEAR_USER_DATA", null, { root: true });
    })
    .catch((error) => {
      console.error(error.message);
      commit("SET_USER_ERROR", error.message);
    });
};

export const updatePassword = ({ commit }, newPassword) => {
  const user = firebase.auth().currentUser;

  return user
    .updatePassword(newPassword)
    .then(() => console.log("The password has been changed"))
    .catch((error) => {
      commit("SET_USER_ERROR", error.message);
      console.log("The password hasn't been changed");
    });
};

export const resetPassword = ({ commit }, payload) => {
  firebase
    .auth()
    .sendPasswordResetEmail(payload.email, payload.actionCodeSettings)
    .catch((error) => {
      commit("SET_USER_ERROR", error.message);
      console.log("The email hasn't been sent");
    });
};

export const verifyPassword = ({ commit }, password) => {
  let user = firebase.auth().currentUser;
  const credential = firebase.auth.EmailAuthProvider.credential(user.email, password);

  return firebase
    .auth()
    .currentUser.reauthenticateWithCredential(credential)
    .then(() => console.log("The password has been verified"))
    .catch((error) => {
      commit("SET_USER_ERROR", error.message);
      throw new Error("The password hasn't been verified");
    });
};
