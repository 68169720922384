<template>
  <a
    href="#"
    @click="handleClick()"
    class="form-button d-block mx-10 mb-4 cursor-pointer"
    :class="{ 'form-button--outline': outline }"
  >
    <slot />
  </a>
</template>

<script>
export default {
  name: "ActionButton",
  props: {
    outline: {
      type: Boolean,
      required: false
    }
  },
  methods: {
    handleClick() {
      this.$emit("action-button-click");
    }
  }
};
</script>

<style lang="scss" scoped>
.form-button {
  @apply px-4 py-2 font-semibold text-white rounded-full border-2 transition duration-500 ease-in-out hover:bg-white;
  background-color: var(--color-main);
  border-color: var(--color-main);
  font-size: 1rem;
}

.form-button:hover {
  color: var(--color-main) !important;
}

.form-button--full {
  display: block;
  width: 100%;
}

.form-button--outline {
  color: var(--color-main) !important;
  background-color: transparent;
}
</style>
