import { firestore, fb, functions } from "@/firebase/config";

const state = {
  id: "",
  title: "",
  email: "",
  address: {
    street: "",
    city: "",
    state: "",
    zip: "",
    country: ""
  },
  admins: [],
  users: [],
  sterilizers: []
};

const getters = {
  admins: (state) => state.admins,
  users: (state) => state.users,
  data: (state) => state,
  sterilizers: (state) => state.sterilizers
};

const mutations = {
  SET_CLINIC_DATA(state, data) {
    Object.assign(state, { ...data });
  }
};

const actions = {
  async fetchClinicData({ commit }, id) {
    const clinicDataRef = firestore.collection("Clinics").doc(id);

    await clinicDataRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          const clinicData = { ...doc.data(), id: id };

          commit("SET_CLINIC_DATA", clinicData);

          return clinicData;
        } else {
          console.error("No such document!");
        }
      })
      .catch((error) => console.error("Error getting document:", error));
  },

  updateClinicData({ commit }, payload) {
    const clinicDataRef = firestore.collection("Clinics").doc(payload.clinicUid);
    const updatedOn = new Date().getTime();

    clinicDataRef
      .update({ ...payload, updatedOn }, { merge: true })
      .then(() => {
        commit("SET_CLINIC_DATA", payload);
        console.log("The document is updated");
      })
      .catch((error) => console.error("Error writing document: ", error));
  },

  fetchUsers(_, clinicUid) {
    const usersRef = firestore.collection("UsersData").where("clinicUid", "==", clinicUid);

    return usersRef.get().catch((error) => console.log("Error getting users documents: ", error));
  },

  async fetchClinics() {
    const clinicDataRef = firestore.collection("Clinics");
    const clinics = [];
    const query = await clinicDataRef.get();

    query.docs.forEach((doc) => {
      clinics.push({ id: doc.id, ...doc.data() });
    });

    return clinics;
  },

  createClinic(_, payload) {
    const clinicDataRef = firestore.collection("Clinics");

    return clinicDataRef
      .add(payload)
      .then((docRef) => {
        console.log("Document written with ID: ", docRef.id);
        return docRef.id;
      })
      .catch((error) => (error.message, error));
  },

  changeUserRank({ dispatch }, userUid) {
    const clinicDataRef = firestore.collection("Clinics").doc(state.id);

    if (state.admins.includes(userUid)) {
      clinicDataRef
        .update({
          admins: fb.firestore.FieldValue.arrayRemove(userUid),
          users: fb.firestore.FieldValue.arrayUnion(userUid)
        })
        .then(() => dispatch("fetchClinicData", state.id));
      return;
    } else if (state.users.includes(userUid)) {
      clinicDataRef
        .update({
          admins: fb.firestore.FieldValue.arrayUnion(userUid),
          users: fb.firestore.FieldValue.arrayRemove(userUid)
        })
        .then(() => dispatch("fetchClinicData", state.id));
    } else {
      console.log("There is no user assiged to this clinic");
    }
  },

  /*
   * @params: {
   *   uid: target user uid,
   *   isDisabled: boolean
   * }
   * @return Object { isDisabled: boolean }
   */
  disableUser({ commit }, userId) {
    const disableUser = functions.httpsCallable("disableUser");

    disableUser(userId)
      .then((response) => commit("userData/SET_USER_DATA", response.data, { root: true }))
      .catch((error) => console.error(error.message));
  },

  deleteUser(_, uid) {
    const deleteUser = functions.httpsCallable("deleteUser");
    deleteUser({ data: uid });
    firestore.collection("UsersData").doc(uid).update({ isDeleted: true }, { merge: true });
  },

  /**
   * @param data {
   *  uid: string - target user,
   *  newPassword: string - at least 6 chars
   * }
   */
  changeUserPassword(_, data) {
    const changeUserPassword = functions.httpsCallable("changeUserPassword");

    changeUserPassword(data)
      .then((response) => console.log("changeUserPassword was called", response))
      .catch((error) => console.error(error.message));
  },

  sendPasswordResetEmail(_, targetUserEmail) {
    const actionCodeSettings = {
      url: "https://ex-spore.com/signin"
    };

    fb.auth()
      .sendPasswordResetEmail(targetUserEmail, actionCodeSettings)
      .then(() => {
        console.log("The reset password email has been sent to ", targetUserEmail);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;

        console.error("An Error occured sending reset password email", errorCode, errorMessage);
      });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
