<template>
  <router-link
    :to="to"
    class="form-button d-block mx-10 mb-2 uppercase"
    :class="{ 'form-button--outline': outline }"
  >
    <slot>{{ name }}</slot>
  </router-link>
</template>

<script>
export default {
  name: "NavButton",
  props: {
    to: {
      required: true,
    },
    outline: {
      type: Boolean,
      required: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.form-button {
  @apply px-4 py-2 font-semibold text-white rounded-full border-2 transition duration-500 ease-in-out hover:bg-white;
  background-color: var(--color-main);
  border-color: var(--color-main);
  font-size: 1rem;
}

.form-button:hover {
  color: var(--color-main) !important;
}

.form-button--outline {
  color: var(--color-main) !important;
  background-color: transparent;
}
</style>
