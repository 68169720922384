import "@/plugins/toaster";
import vuetify from "@/plugins/vuetify";
import VeeValidate from "vee-validate";
import Vue from "vue";
import JsonCSV from "vue-json-csv";
import App from "./App.vue";
import "./assets/styles.scss";
import "./assets/toaster.scss";
import "./firebase/config";
import { firebaseAuth } from "./firebase/config";
import router from "./router";
import { store } from "./store";

Vue.use(VeeValidate, { fieldsBagName: "veeFields" });
Vue.component("downloadCsv", JsonCSV);
Vue.config.productionTip = false;

/** Equels to 8 hours */
const SESSION_DURATION = 1000 * 60 * 60 * 8;

let sessionTimeout = null;

function setSessionTimeout(callback) {
  clearTimeout(sessionTimeout);

  sessionTimeout = setTimeout(() => {
    console.log("You was signed out");
    callback();
  }, SESSION_DURATION);
}

router.beforeEach((to, from, next) => {
  const user = firebaseAuth.currentUser;
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

  if (requiresAuth && !user) {
    next("/signin");
  } else if (requiresAuth && user) {
    store.dispatch("auth/checkPermissions", user.uid);

    next();
  } else {
    next();
  }
});

firebaseAuth.onAuthStateChanged(async user => {
  new Vue({
    el: "#app",
    store: store,
    router: router,
    vuetify,
    render: h => h(App),
    created() {
      if (user) {
        setSessionTimeout(() => {
          this.$store.dispatch("auth/signOut").then(() => {
            this.$router.push("/signin");
          });
        });

        /**
         * Read uses's claims
         */
        // user.getIdTokenResult().then((tokenResult) => {
        //   console.log("claims", tokenResult.claims);
        // });

        this.$store.commit("auth/SET_USER", {
          uid: user.uid,
          authEmail: user.email
        });

        /* 
        Compare creation data and last sesion date; 
        Don't fetch data for the new user 
        */
        if (user.metadata.a !== user.metadata.b) {
          this.$store.dispatch("userData/fetchUserData", user.uid).then(() => {
            this.$store
              .dispatch("auth/fetchCurrentUserData", user.uid)
              .then(() => {
                if (!this.$store.state.auth.data.clinicUid) {
                  return;
                }

                this.$store.dispatch(
                  "clinic/fetchClinicData",
                  this.$store.state.auth.data.clinicUid
                );
              });
          });
        }
      } else {
        sessionTimeout && clearTimeout(sessionTimeout);
        sessionTimeout = null;

        this.$store.commit("auth/SET_USER", null);
      }
    }
  });
});
