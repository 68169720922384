<template>
  <div class="w-full flex flex-row justify-between">
    <label
      :for="option.name"
      v-for="(option, index) in options"
      :key="index"
      class="text-xl font-semibold uppercase"
      :class="option.color"
    >
      <input
        type="radio"
        name="radio-input"
        :value="option.name"
        :id="option.name"
        @change="$emit('input', option.name)"
      />
      {{ option.name }}
    </label>
  </div>
</template>

<script>
export default {
  name: "BaseRadioButtonGroup",
  model: {
    event: "input",
    prop: "selected",
  },
  props: {
    selected: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      options: [
        { name: "Pass", color: "text-green-500" },
        { name: "Fail", color: "text-red-500" },
      ],
    };
  },
};
</script>

<style>
.radio-button-group {
  width: 60%;
  margin: 20px auto;
  padding: 15px 20px;
  border: 1px solid black;
  border-radius: 8px;

  display: flex;
  justify-content: space-between;
}
</style>
