<template>
  <app-layout>
    <template #title>USER SETTINGS</template>
    <template #content>
      <p class="text-center mt-10">
        You can update your email address and password that are used for login
      </p>
      <transition name="fade">
        <p
          v-if="error"
          class="bg-red-100 p-5 my-5 border border-red-200 rounded text-red-500"
        >
          {{ error }}
        </p>
      </transition>
      <form
        class="flex flex-col justify-center space-y-10 mx-auto mt-10"
        @submit.prevent="validateBeforeSubmit"
      >
        <div>
          <p class="text-center">E-mail</p>
          <form-input
            class="w-full"
            id="email"
            name="email"
            type="text"
            placeholder="E-mail"
            :value="currentUser.email"
            disabled
          />
          <span v-show="errors.has('email')" class="text-xs text-red-500">{{
            errors.first("email")
          }}</span>
        </div>

        <div>
          <p class="text-center">Password</p>
          <form-input
            class="w-full"
            id="password"
            name="password"
            type="password"
            placeholder="Password"
            v-validate="'required'"
            v-model="password"
          />
          <span v-show="errors.has('password')" class="text-xs text-red-500">{{
            errors.first("password")
          }}</span>
        </div>

        <!-- <div class="mt-6 p-4 border-2 border-gray-300">
          <h2 class="text-center">Registration code</h2>
          <form-input
            class="w-full mt-2"
            id="code"
            name="code"
            type="text"
            placeholder="Code"
            :value="code"
            disabled
          />
          <div class="flex felx-row justify-between mt-4">
            <div class="flex flex-col text-center">
              <span>Registration Date</span>
              <span>{{ registrationDate }}</span>
            </div>
            <div class="flex flex-col text-center">
              <span> Expiration Date</span>
              <span>{{ expirationDate }}</span>
            </div>
          </div>
        </div> -->

        <div class="mt-10 mb-4 flex justify-center items-center mx-auto">
          <base-button type="submit" @click="updateProfile()" ring
            >Save</base-button
          >
        </div>
      </form>
    </template>
  </app-layout>
</template>

<script>
import AppLayout from "@/layouts/AppLayout";
import FormInput from "@/components/inputs/FormInput";
import BaseButton from "@/components/buttons/BaseButton";
import { mapGetters } from "vuex";
import { DateTime } from "luxon";

export default {
  name: "Settings",
  components: { AppLayout, FormInput, BaseButton },
  data() {
    return {
      password: "",
      code: "777-777",
      registrationDate: "",
      expirationDate: "",
    };
  },
  methods: {
    validateBeforeSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          // eslint-disable-next-line

          this.handleSubmit();

          return;
        }
        console.log("The form is not submitted. Correct the errors");
      });
    },
    handleSubmit() {
      this.$store.dispatch("auth/updatePassword", this.password).then(() => {
        this.$router.push("/profile");
      });
    },
    setRegistrationDate() {
      this.registrationDate = new DateTime.now().toFormat("dd/LL/yyyy");
    },
    setExpirationDate() {
      this.expirationDate = new DateTime.now()
        .plus({ months: 1 })
        .toFormat("dd/LL/yyyy");
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/user",
      error: "auth/error",
    }),
  },
  mounted() {
    this.setRegistrationDate();
    this.setExpirationDate();
  },
};
</script>
