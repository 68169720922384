<template>
  <div class="relative border-b-2 border-primary focus-within:border-primary">
    <input
      class="block w-full appearance-none focus:outline-none bg-transparent text-center placeholder-gray-500 placeholder-opacity-50"
      :class="{ 'bg-gray-100 cursor-not-allowed': disabled }"
      :disabled="disabled"
      :id="id"
      :placeholder="placeholder"
      :required="required"
      :type="type"
      :value="value"
      @input="handleInputEvent($event)"
    />
    <label v-if="label.length > 0" :for="id" class="absolute top-0 left-0 z-10 text-left duration-300 origin-0">
      {{ label }}
    </label>
  </div>
</template>

<script>
export default {
  name: "FormInput",
  model: {
    prop: "value",
    event: "input"
  },
  props: {
    disabled: { type: Boolean, default: false },
    id: { type: String, default: "" },
    label: { type: String, default: "" },
    placeholder: { type: String, default: "" },
    required: { type: Boolean, default: false },
    type: { type: String, default: "text" },
    value: { type: String, default: "" }
  },
  methods: {
    handleInputEvent(event) {
      this.$emit("input", event.target.value);
    }
  }
};
</script>
