<template>
  <app-layout>
    <template #title>STERILIZERS</template>
    <template #content>
      <h2 class="text-lg text-semibold text-center mb-6">
        Please enter the sterilizer's serial number and name of manufacturer.
      </h2>
      <div class="mb-4 flex justify-center items-center">
        <base-button type="submit" @click="addRecord()" primary>Add Sterilizer + Cycle</base-button>
      </div>
      <div class="w-full">
        <div v-for="(sterilizer, index) in sterilizers" :key="index">
          <div class="flex flex-row justify-between items-center mt-8">
            <div class="flex flex-row space-x-3 w-1/2 mr-2">
              <span class="w-1/2 border-b-2 border-primary mb-1">
                <p class="mx-2 mb-1 text-lg truncate">
                  {{ sterilizer.title }}
                </p>
              </span>
              <span class="w-1/2 border-b-2 border-primary mb-1">
                <p class="mx-2 mb-1 text-lg truncate">
                  {{ sterilizer.number }}
                </p>
              </span>
            </div>
            <div class="flex flex-row space-x-2">
              <base-button @click="pushToEdit(index)" outline>Edit</base-button>
              <base-button @click="removeRecord(index)" outline>Delete</base-button>
            </div>
          </div>
        </div>
      </div>
    </template>
  </app-layout>
</template>

<script>
import { BaseButton } from "@/components";
import AppLayout from "@/layouts/AppLayout";
import { mapGetters } from "vuex";

export default {
  name: "ListSterilizers",
  components: { AppLayout, BaseButton },

  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      targetUser: "userData/targetUser",
      clinic: "clinic/data"
    })
  },

  created() {
    console.log(this.clinic);
    this.$store.dispatch("userData/fetchUserData", this.targetUser.uid);
    this.$store.dispatch("clinic/fetchClinicData", this.clinic.id);
    this.sterilizers = this.$store.getters["clinic/sterilizers"];
    console.log(this.sterilizers);
  },

  methods: {
    addRecord() {
      this.sterilizers.push({
        title: "",
        number: "",
        cycles: []
      });

      const index = this.sterilizers.findIndex((el) => el.title === "");

      this.$router.push({
        name: this.currentUser.uid == this.targetUser.uid ? "SelfEditSterilizers" : "UserEditSterilizers",
        params: { id: this.targetUser.uid, sterilizerId: index }
      });
    },

    removeRecord(index) {
      const deletedSterilizer = this.sterilizers[index];
      if (index > -1) {
        this.sterilizers.splice(index, 1);
      }
      this.$store
        .dispatch("clinic/updateClinicData", {
          clinicUid: this.targetUser.clinicUid,
          sterilizers: this.sterilizers
        })
        .then(() => {
          this.$store.dispatch("audit/addRecord", {
            actionName: "DELETE_STERILIZER",
            clinicUid: this.currentUser.clinicUid,
            notes: `${this.currentUser.fullName} deleted sterilizer: ${JSON.stringify(deletedSterilizer)}`,
            uid: this.currentUser.uid,
            userEmail: this.currentUser.email,
            userName: this.currentUser.userName
          });
        });
    },

    pushToEdit(index) {
      this.$router.push({
        name: this.currentUser.uid == this.targetUser.uid ? "SelfEditSterilizers" : "UserEditSterilizers",
        params: { id: this.targetUser.uid, sterilizerId: index }
      });
    }
  }
};
</script>
