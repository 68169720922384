import AddEmail from "@/views/profile/AddEmail.vue";
import ClinicPage from "@/views/profile/ClinicPage";
import Settings from "@/views/profile/Settings";
import EditSterilizer from "@/views/profile/Sterilizers/EditSterilizer";
import ListSterilizers from "@/views/profile/Sterilizers/ListSterilizers";
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Index",
    component: () => import(/* webpackChunkName: "menu" */ "../views/Index.vue")
  },
  {
    path: "/submit-result",
    name: "SubmitResultPage",
    component: () => import(/* webpackChunkName: "SubmitResultPage" */ "../views/SubmitResultPage.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/results",
    name: "Results",
    component: () => import(/* webpackChunkName: "Results" */ "../views/Results.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/audit-trail",
    name: "Audit Trail",
    component: () => import(/* webpackChunkName: "AuditTrail" */ "../views/AuditTrail.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/signin",
    name: "SignIn",
    component: () => import(/* webpackChunkName: "signin" */ "../views/auth/SignIn.vue"),
    meta: { requiresAuth: false }
  },
  {
    path: "/signup",
    name: "SingUp",
    component: () => import(/* webpackChunkName: "signup" */ "../views/auth/SignUp.vue"),
    meta: { requiresAuth: false }
  },
  {
    path: "/update-password",
    name: "UpdatePassword",
    component: () => import(/* webpackChunkName: "about" */ "../views/auth/UpdatePassword.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/reset",
    name: "PasswordReset",
    component: () => import(/* webpackChunkName: "PasswordReset" */ "../views/auth/PasswordReset.vue"),
    meta: { requiresAuth: false }
  },
  {
    path: "/users",
    name: "Users",
    component: () => import(/* webpackChunkName: "Results" */ "../views/UsersPage.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/users/add-operator",
    name: "AddOperator",
    component: () => import(/* webpackChunkName: "Results" */ "../views/profile/AddOperatorPage"),
    meta: { requiresAuth: true }
  },
  {
    path: "/users/:id",
    name: "ManageUser",
    component: () => import(/* webpackChunkName: "Results" */ "../views/users/index.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/users/:id/emails",
    name: "UserEditEmails",
    component: AddEmail,
    meta: { requiresAuth: true }
  },
  {
    path: "/users/:id/sterilizers",
    name: "UserListSterilizers",
    component: ListSterilizers,
    meta: { requiresAuth: true }
  },
  {
    path: "/users/:id/sterilizers/:sterilizerId",
    name: "UserEditSterilizers",
    component: EditSterilizer,
    meta: { requiresAuth: true }
  },
  {
    path: "/profile",
    alias: "/profile/change-password",
    name: "Profile",
    component: () => import(/* webpackChunkName: "about" */ "../views/profile/index.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/sterilizers",
    name: "SelfListSterilizers",
    component: ListSterilizers,
    meta: { requiresAuth: true }
  },
  {
    path: "/sterilizers/:sterilizerId",
    name: "SelfEditSterilizers",
    component: EditSterilizer,
    meta: { requiresAuth: true }
  },
  {
    path: "/profile/settings",
    name: "settings",
    component: Settings,
    meta: { requiresAuth: true }
  },
  {
    path: "/clinic",
    name: "clinic",
    component: ClinicPage,
    meta: { requiresAuth: true }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
