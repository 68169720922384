<template>
  <app-layout>
    <template #title>Clinic</template>
    <template #content>
      <!-- <template v-if="!currentUser.clinicUid && selectedClinic == ''">
        <div class="mt-20 flex">
          <form-select :options="clinicTitles" v-model="selectedClinic" :inline="false" />
        </div>
      </template>
      <template v-else-if="selectedClinic == 'New Clinic'">
        <ClinicForm :clinicUid="''" />
      </template>
      <template v-else-if="clinicData">
        <ClinicForm :clinicUid="clinicData.id" />
      </template>
      <template v-else>
        <ClinicForm :clinicUid="currentUser.clinicUid" />
      </template>
      </template>-->

      <ClinicForm :clinicUid="currentUser.clinicUid" />
    </template>
  </app-layout>
</template>

<script>
import AppLayout from "@/layouts/AppLayout";
import ClinicForm from "./ClinicForm";
// import FormSelect from "@/components/inputs/FormSelect.vue";

import { mapGetters } from "vuex";

export default {
  components: {
    AppLayout,
    ClinicForm
    //FormSelect
  },
  name: "clinic",
  data() {
    return {
      clinics: [],
      selectedClinic: ""
    };
  },
  methods: {
    handleClinicSelect(value) {
      this.clinics.filter(clinic => {
        return clinic.title == value;
      });
    }
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser"
    }),
    clinicTitles() {
      const array = this.clinics.map(clinic => {
        return clinic.title;
      });

      array.unshift("New Clinic");

      return array;
    },
    clinicData() {
      return this.clinics.filter(clinic => {
        return clinic.title == this.selectedClinic;
      })[0];
    }
  },
  created() {
    this.$store.dispatch("auth/fetchCurrentUserData", this.currentUser.uid);
    this.$store.dispatch("clinic/fetchClinics").then(clinics => {
      this.clinics = clinics;
    });
  }
};
</script>

<style lang="scss">
.centered-input.v-text-field .v-label {
  left: 50% !important;
  transform: translateX(-50%);
  transform-origin: top 50%;
  &.v-label--active {
    transform: translateY(-18px) scale(0.75) translateX(-50%);
  }
}
</style>
