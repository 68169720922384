<template>
  <div class="mx-auto" :class="{'w-full flex flex-row justify-between': inline}">
    <label for="select" class="font-semibold block py-2" v-if="label">
      {{
      label
      }}
    </label>

    <div class="relative ml-2 w-64">
      <div class="h-10 bg-white flex border border-gray-200 rounded items-center">
        <span
          class="px-4 appearance-none outline-none text-gray-800 w-full"
          @click="open = !open"
        >{{ selected == "" ? "-- Select --" : selected }}</span>

        <label
          @click="open = !open"
          for="show_more"
          class="cursor-pointer outline-none focus:outline-none transition-all text-red-300 hover:text-red-600"
        >
          <svg
            class="w-4 h-4 mx-2 fill-current"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <polyline points="18 15 12 9 6 15" />
          </svg>
        </label>
      </div>

      <div
        class="absolute rounded shadow bg-white overflow-hidden peer-checked:flex flex-col w-full mt-1 border border-gray-200 z-10"
        v-show="open"
      >
        <div class="cursor-pointer group" v-for="(option, index) of options" :key="index">
          <a
            @click="handleSelect(option)"
            class="block p-2 border-transparent border-l-4 group-hover:border-red-600 group-hover:bg-red-100"
          >{{ option }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      required: true
    },
    selected: {
      type: String,
      required: false
    },
    label: {
      type: String,
      required: false
    },
    inline: {
      type: Boolean,
      default: true,
      required: false
    }
  },
  model: {
    prop: "selected",
    event: "input"
  },
  data() {
    return {
      open: false
    };
  },
  methods: {
    handleSelect(option) {
      this.open = false;
      this.$emit("input", option);
    }
  },
  watch: {
    options() {
      this.$emit("input", "");
    }
  },
  mounted() {
    this.$emit("input", "");
  }
};
</script>
