<template>
  <app-layout>
    <template #title>Add Sterilizer</template>
    <template #content>
      <form @submit.prevent="updateProfile()">
        <div class="mb-6 flex flex-col content-between items-center space-y-6">
          <form-input placeholder="Sterilizer name" id="title" v-model="title" required />
          <form-input placeholder="Serial number" id="number" v-model="number" required />
          <base-button @click="addCycle()" primary>Add a cycle</base-button>
        </div>
        <div v-for="(cycle, index) in cycles" :key="index">
          <div class="flex flex-row items-center justify-between mt-8">
            <form-input
              class="mr-4 w-full"
              id="number"
              :placeholder="`Cycle Name ${index + 1}`"
              v-model="cycles[index]"
              @change="updateCycle($event, index)"
            />
            <base-button @click="removeCycle(index)">Delete</base-button>
          </div>
        </div>
        <div class="mt-10 pb-20 flex justify-center items-center">
          <base-button type="submit" ring>Save</base-button>
        </div>
      </form>
    </template>
  </app-layout>
</template>

<script>
import { BaseButton, FormInput } from "@/components";
import AppLayout from "@/layouts/AppLayout";
import { mapGetters } from "vuex";

export default {
  name: "EditSterilizer",
  components: { AppLayout, FormInput, BaseButton },
  data: () => ({
    title: "",
    number: "",
    cycles: []
  }),
  methods: {
    addCycle() {
      if (!this.cycles || this.cycles[this.cycles.length - 1] !== "") {
        this.cycles?.push("") || (this.cycles = [""]);
      }
    },
    updateCycle(event, index) {
      this.cycles[index] = event;
    },
    removeCycle(index) {
      if (index > -1) {
        this.cycles.splice(index, 1);
      }
    },

    updateProfile() {
      let sterilizer = {
        title: this.title,
        number: this.number,
        cycles: this.cycles
      };
      let sterilizers = this.sterilizers;
      let index = this.$route.params.sterilizerId;

      sterilizers[index] = sterilizer;

      // remove if the item's data was not provied
      if (sterilizer.title == "") {
        sterilizers.splice(index, 1);
      }

      this.$store
        .dispatch("clinic/updateClinicData", { clinicUid: this.targetUser.clinicUid, sterilizers: sterilizers })
        .then(() => {
          if (this.currentUser.uid == this.targetUser.uid) {
            this.$store.dispatch("auth/fetchCurrentUserData", this.currentUser.uid);
          }
        })
        .then(() => {
          this.$store.dispatch("audit/addRecord", {
            actionName: "ADD_STERILIZER",
            clinicUid: this.currentUser.clinicUid,
            notes: `Sterilizer added/edited: ${JSON.stringify(sterilizer)}`,
            uid: this.currentUser.uid,
            userEmail: this.currentUser.email,
            userName: this.currentUser.userName
          });
        })
        .then(() => this.pushBack());
    },
    pushBack() {
      const fullPath = this.$route.fullPath.split("/");
      const backPath = fullPath.slice(0, fullPath.length - 1).join("/");

      this.$router.push(backPath);
    }
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      targetUser: "userData/targetUser",
      sterilizers: "clinic/sterilizers"
    })
  },
  created() {
    const sterilizer = {
      ...this.sterilizers[this.$route.params.sterilizerId]
    };

    this.title = sterilizer.title;
    this.number = sterilizer.number;
    this.cycles = sterilizer.cycles;
  }
};
</script>
