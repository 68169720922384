import { fireStorage, firestore, functions } from "@/firebase/config";

const state = {};

const mutations = {
  SET_RESULTS_DATA(state, data) {
    Object.assign(state, data);
  }
};

const actions = {
  async fetchResults(_, userId) {
    const formDataRef = await firestore
      .collection("FormData")
      .doc(userId)
      .collection("result")
      .orderBy("timestamp", "desc")
      .get();

    const results = await formDataRef.docs.map((doc) => doc.data());

    return results;
  },

  async fetchLastResultData(_, userId) {
    const formDataRef = await firestore
      .collection("FormData")
      .doc(userId)
      .collection("result")
      .orderBy("timestamp", "desc")
      .limit(1)
      .get();

    const result = await formDataRef.docs.map((doc) => doc.data())[0];

    return result;
  },

  async emailResult(_, result) {
    const emailResult = functions.httpsCallable("emailResult");
    await emailResult(result)
      .then((res) => console.log(res))
      .catch((err) => console.error(err));
  },

  async getImageUrl(_, imageName) {
    const imageRef = await fireStorage.ref().child(`Photos/${imageName}`);
    const imageUrl = await imageRef.getDownloadURL().then((url) => {
      return url;
    });

    return imageUrl;
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
