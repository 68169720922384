import { firestore } from "@/firebase/config";

const state = {
  records: [],
  error: ""
};

const getters = {
  getRecords: (state) => state.records
};

const mutations = {
  SET_AUDIT_DATA(state, data) {
    Object.assign(state, { ...data });
  },
  SET_RECORDS: (state, payload) => {
    state.records = payload;
  },
  SET_ERROR(state, error) {
    state.error = error;
  }
};

const actions = {
  addRecord({ commit }, payload) {
    firestore
      .collection("Audit")
      .doc()
      .set({ ...payload, timestamp: new Date().getTime() })
      .then(() => console.log("User action has been logged"))
      .catch((error) => {
        commit("SET_ERROR", error.message);
        console.error("Error writing document: ", error);
      });
  },

  async fetchAllRecords({ commit }) {
    await firestore
      .collection("Audit")
      .get()
      .then((querySnapshot) => {
        const records = [];
        querySnapshot.forEach((doc) => records.push(doc.data()));
        commit("SET_RECORDS", records);
      })
      .catch((error) => {
        commit("SET_ERROR", error.message);
        console.error("Error getting documents: ", error);
      });
  },

  async fetchRecordsFromUser({ commit }, email) {
    await firestore
      .collection("Audit")
      .where("userEmail", "==", email)
      .get()
      .then((querySnapshot) => {
        const records = [];
        querySnapshot.forEach((doc) => records.push(doc.data()));
        commit("SET_RECORDS", records);
      })
      .catch((error) => commit("SET_ERROR", error.message));
  },

  async fetchRecordsFromClinic({ commit }, clinicUid) {
    await firestore
      .collection("Audit")
      .where("clinicUid", "==", clinicUid)
      .get()
      .then((querySnapshot) => {
        const records = [];
        querySnapshot.forEach((doc) => records.push(doc.data()));
        commit("SET_RECORDS", records);
      })
      .catch((error) => {
        commit("SET_ERROR", error.message);
        console.error("Error getting documents: ", error);
      });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
