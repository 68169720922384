// import { fb, firestore, functions } from "@/firebase/config";
import { firestore, functions } from "@/firebase/config";
import * as auth from "@/service/AuthService.js";
import assignDeep from "assign-deep";

const state = {
  user: undefined,
  data: {},
  error: ""
};

const getters = {
  user: (state) => state.user,
  isDisabled: (state) => state.data.isDisabled,
  currentUser: (state) => ({ ...state.user, ...state.data }),
  error: (state) => state.error
};

const mutations = {
  SET_USER(state, user) {
    Object.assign(state, { user });
  },
  SET_CURRENT_USER_DATA(state, data) {
    assignDeep(state, { data });
  },
  SET_USER_ERROR(state, error) {
    state.error = error;
  }
};

const actions = {
  signUp({ commit }, payload) {
    auth.signUp({ commit }, payload);
  },

  signIn({ commit }, payload) {
    return auth.signIn({ commit }, payload);
  },

  signOut({ commit }) {
    auth.signOut({ commit });
  },

  updatePassword({ commit, rootGetters }, password) {
    auth.updatePassword({ commit }, password).then(() => {
      firestore
        .collection("UsersData")
        .doc(rootGetters["auth/user"].uid)
        .update({ updatedOn: new Date().getTime() }, { merge: true });
    });
  },

  resetPassword({ commit }, payload) {
    auth.resetPassword({ commit }, payload);
  },

  async createUser({ rootGetters }, payload) {
    const randomPassword = Math.random().toString(36).slice(-6);
    const createUser = functions.httpsCallable("createUser");

    await createUser({
      email: payload.email,
      password: randomPassword,
      displayName: payload.fullName,
      clinicUid: rootGetters["userData/clinicUid"],
      adminFullName: rootGetters["auth/currentUser"].fullName || `${rootGetters["clinic/data"].title} Admin`,
      clinicTitle: rootGetters["clinic/data"].title
    })
      .then((res) => console.log("createUser:", res))
      .catch((error) => {
        console.error("Error creating user:", error);

        if (error.code == "auth/email-already-in-use") {
          alert("User already exists. Try to log in");
        }
      });
  },

  async checkRegistrationCode(_, code) {
    return await firestore
      .collection("RegistrationCodes")
      .where("code", "==", code)
      .get()
      .catch((error) => console.log("Error getting documents: ", error));
  },

  setCodeInactive(_, id) {
    const codeRef = firestore.collection("RegistrationCodes").doc(id);

    codeRef.set({ isActive: false }, { merge: true });
  },

  userSetFormData({ state }, data) {
    const formDataRef = firestore.collection("FormData").doc(state.user.uid);

    formDataRef
      .collection("result")
      .doc()
      .set(data)
      .then(() => {
        // remove
        console.log("action >>", data);
        console.log("Document successfully updated!");
      })
      .catch((error) => console.error("Error writing document: ", error));
  },

  async fetchCurrentUserData({ commit }, uid) {
    const userDataRef = firestore.collection("UsersData").doc(uid);

    await userDataRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          commit("SET_USER", { email: doc.data().email, uid: doc.data().uid });
          commit("SET_CURRENT_USER_DATA", doc.data());
        } else {
          console.error("No such document!");
        }
      })
      .catch((error) => console.error("Error getting document:", error));
  },

  async checkPermissions({ dispatch }, uid) {
    const metedataRef = firestore.collection("metadata").doc(uid);

    await metedataRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          const data = doc.data();

          if (data.isDisabled) dispatch("signOut");

          return data;
        } else {
          console.error("Check Permissions: No such document!");
        }
      })
      .catch((error) => console.error("Check Permissions: Error getting document:", error));
  },

  async verifyPassword({ commit }, password) {
    await auth
      .verifyPassword({ commit }, password)
      .then(() => console.log("Password is correct"))
      .catch(() => {
        throw new Error("Password is incorrect");
      });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
