<template>
  <section class="w-full md:w-1/2 lg:w-1/3 p-5 mx-auto h-screen">
    <!--  Header.  -->
    <div id="header" class="flex items-start justify-center mb-4">
      <a class="form-button form-button--mini form-button--back form-button--outline uppercase absolute left-0"
        @click="pushBack()">Back</a>
      <h1 class="title">
        <slot name="title" />
      </h1>
    </div>

    <!--  Main.  -->
    <div class="pb-28">
      <slot name="content" />
    </div>

    <!--  Footer.  -->
    <Footer />
  </section>
</template>

<script>
import Footer from "@/components/Footer.vue";

export default {
  name: "AuthedLayout",
  components: {
    Footer
  },
  methods: {
    pushBack() {
      const fullPath = this.$route.fullPath.split("/");

      if (fullPath.includes("edit-sterilizer")) {
        this.$router.push("/profile/list-sterilizers");
      } else if (fullPath.length > 2) {
        const backPath = fullPath.slice(0, fullPath.length - 1).join("/");

        this.$router.push(backPath);
      } else {
        this.$router.push("/");
      }
    }
  }
};
</script>

<style scoped>
.form-button {
  font-size: 1rem;
}
</style>