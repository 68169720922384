import Vue from "vue";
import Vuex from "vuex";

import auth from "./modules/auth";
import userData from "./modules/userData";
import clinic from "./modules/clinic";
import results from "./modules/results";
import audit from "./modules/audit";

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    auth,
    userData,
    clinic,
    results,
    audit
  }
});
