var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{class:[
    {
      'px-8 py-2 text-sm text-white uppercase rounded-full bg-primary border-solid border-2 border-primary transition duration-500 ease-in-out hover:bg-white hover:text-primary':
        _vm.primary
    },
    {
      'text-normal font-semibold text-primary border-solid border-2 border-primary rounded-3xl py-1 px-4 hover:text-white hover:bg-primary':
        _vm.outline
    },
    {
      'px-8 py-1 text-white text-sm font-normal uppercase bg-primary ring-2 ring-offset-2 ring-primary border-2 border-white rounded-full hover:text-primary hover:bg-white transition duration-300 ease-in-out':
        _vm.ring
    },
    {
      'px-6 py-2 text-sm rounded-full bg-gray border-solid border-2 border-gray cursor-not-allowed opacity-70':
        _vm.disable
    }
  ],attrs:{"role":"button"},on:{"click":function($event){return _vm.$emit('click', $event)}}},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }