<template>
  <app-layout>
    <template #title>Emails</template>
    <template #content>
      <div class="flex flex-col space-y-2 mt-20">
        <v-text-field class="centered-input" v-model="targetUser.email" label="Email" type="email" required />
      </div>

      <v-checkbox
        v-model="targetUser.noPrivateNotifications"
        label="Do not send results to my private email address."
      />

      <div class="mt-10 pb-20 flex justify-center items-center">
        <base-button @click="updateProfile()" ring>Save</base-button>
      </div>
    </template>
  </app-layout>
</template>

<script>
import AppLayout from "@/layouts/AppLayout";
import BaseButton from "@/components/buttons/BaseButton";
import { mapGetters } from "vuex";

export default {
  components: { AppLayout, BaseButton },
  name: "add-email",
  methods: {
    updateProfile() {
      this.$store
        .dispatch("userData/updateUserData", {
          uid: this.targetUid,
          email: this.targetUser.email
        })
        .then(() => this.$router.push(`/users/${this.targetUid}`))
        .then(() => {
          this.$store.dispatch("audit/addRecord", {
            actionName: "CHANGE_USER_DATA",
            clinicUid: this.currentUser.clinicUid,
            notes: `Changed user email to ${this.targetUser.email}`,
            uid: this.currentUser.uid,
            userEmail: this.currentUser.email,
            userName: this.currentUser.userName
          });
        });
    }
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      targetUser: "userData/targetUser"
    }),
    targetUid() {
      return this.$route.params.id == undefined ? this.currentUser.uid : this.$route.params.id;
    }
  },
  created() {
    this.$store.dispatch("userData/fetchUserData", this.targetUid);
    this.targetUser.email ??= this.targetUser.email;
  }
};
</script>
