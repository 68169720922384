<template>
  <form class="flex flex-col space-y-4" @submit.prevent="validateBeforeSubmit">
    <v-text-field
      class="mt-20 centered-input"
      v-model="title"
      :rules="[rules.required, rules.minLength]"
      label="Enter business name of Clinic"
      id="title"
      :disabled="formDisabled"
      required
    />
    <v-text-field
      class="centered-input"
      v-model="email"
      :rules="[rules.required, rules.minLength, rules.email]"
      label="Email"
      id="email"
      :disabled="formDisabled"
      required
    />
    <v-text-field
      class="centered-input"
      v-model="address.street"
      :rules="[rules.required, rules.minLength]"
      label="Street"
      id="street"
      :disabled="formDisabled"
      required
    />
    <v-text-field
      class="centered-input"
      v-model="address.city"
      :rules="[rules.required, rules.minLength]"
      label="City"
      id="city"
      :disabled="formDisabled"
      required
    />
    <v-text-field
      class="centered-input"
      v-model="address.state"
      :rules="[rules.required, rules.minLength]"
      label="State"
      id="state"
      :disabled="formDisabled"
      required
    />
    <v-text-field
      class="centered-input"
      v-model="address.zip"
      :rules="[rules.required, rules.minLength]"
      label="Zip"
      id="zip"
      :disabled="formDisabled"
      required
    />
    <v-text-field
      class="centered-input"
      v-model="address.country"
      :rules="[rules.required, rules.minLength]"
      label="Country"
      id="country"
      :disabled="formDisabled"
      required
    />
    <div class="mt-4 pb-20 flex justify-center items-center">
      <base-button type="submit" ring>{{ getSubmitActionTitle() }}</base-button>
    </div>
  </form>
</template>

<script>
import { BaseButton } from "@/components";
import { mapGetters } from "vuex";

export default {
  name: "ClinicForm",
  components: { BaseButton },
  props: {
    clinicUid: {
      type: String || undefined,
      required: true
    }
  },
  data() {
    return {
      title: "",
      email: "",
      address: {
        street: "",
        city: "",
        state: "",
        zip: "",
        country: ""
      },
      rules: {
        required: (value) => !!value || "Required.",
        minLength: (value) => value.length >= 3 || "Field must be longer than 3 characters",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        }
      }
    };
  },
  methods: {
    validateBeforeSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.submitResult();
          return;
        }
      });
    },
    submitResult() {
      if (!this.errors.items.length) {
        const clinicUid = this.clinicUid;

        const data = {
          email: this.email,
          title: this.title,
          address: this.address
        };

        clinicUid == ""
          ? this.setClinic(data)
          : this.currentUser.clinicUid == ""
          ? this.selectClinic()
          : this.updateClinic({ clinicUid, ...data });
      }
    },
    setClinic(payload) {
      const data = {
        ...payload,
        createdAt: new Date().getTime(),
        admins: [this.currentUser.uid],
        users: []
      };

      this.$store
        .dispatch("clinic/createClinic", data)
        .then((clinicUid) => {
          this.$store.dispatch("userData/updateUserData", {
            uid: this.currentUser.uid,
            clinicUid: clinicUid
          });
        })
        .then(() => {
          this.$store.dispatch("audit/addRecord", {
            actionName: "CREATE_CLINIC",
            clinicUid: this.currentUser.clinicUid,
            notes: `Created clinic ${this.title}`,
            uid: this.currentUser.uid,
            userEmail: this.currentUser.email,
            userName: this.currentUser.userName
          });
        })
        .then(() => this.$router.push("/"));
    },
    async selectClinic() {
      const users = this.clinic.users;

      console.log("before >>", users);
      users.push(this.currentUser.uid);
      console.log("after >>", users);

      this.$store.dispatch("clinic/updateClinicData", {
        clinicUid: this.clinicUid,
        data: { users: users }
      });

      this.$store
        .dispatch("userData/updateUserData", {
          uid: this.currentUser.uid,
          clinicUid: this.clinicUid
        })
        .then(() => this.$router.push("/"));
    },
    updateClinic(payload) {
      this.$store
        .dispatch("clinic/updateClinicData", payload)
        .then(() => this.$router.push("/"))
        .then(() => {
          this.$store.dispatch("audit/addRecord", {
            actionName: "CHANGE_CLINIC",
            clinicUid: this.currentUser.clinicUid,
            notes: `Clinic data has been updated: ${JSON.stringify(payload)}`,
            uid: this.currentUser.uid,
            userEmail: this.currentUser.email,
            userName: this.currentUser.userName
          });
        });
    },
    getSubmitActionTitle() {
      return this.clinicUid == "" ? "Create" : this.currentUser.clinicUid == "" ? "Select" : "Save";
    }
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      clinic: "clinic/data"
    }),
    formDisabled() {
      if (this.clinicUid == "" || (this.clinicUid && this.clinic.admins.includes(this.currentUser.uid))) {
        return false;
      }
      return true;
    }
  },
  created() {
    if (this.clinicUid == "") {
      return;
    }

    this.$store.dispatch("clinic/fetchClinicData", this.clinicUid).then(() => {
      this.title = this.clinic.title;
      this.email = this.clinic.email;
      this.address.street = this.clinic.address.street;
      this.address.city = this.clinic.address.city;
      this.address.state = this.clinic.address.state;
      this.address.zip = this.clinic.address.zip;
      this.address.country = this.clinic.address.country;
    });
  }
};
</script>
