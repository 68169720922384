import { firebaseAuth, firestore } from "@/firebase/config";

const state = {
  clinicUid: "",
  isDisabled: false,
  uid: "",
  error: ""
};

const getters = {
  isDisabled: (state) => state.isDisabled,
  clinicUid: (state) => state.clinicUid,
  error: (state) => state.error,
  targetUser: (state) => state
};

const mutations = {
  SET_USER_DATA(state, data) {
    Object.assign(state, data);
  },

  CLEAR_USER_DATA() {
    Object.assign(state, {
      clinicUid: "",
      uid: "",
      error: ""
    });
  },

  SET_ERROR(state, error) {
    Object.assign(state, { error });
  }
};

const actions = {
  async fetchUserData({ commit }, uid) {
    const userDataRef = firestore.collection("UsersData").doc(uid);

    await userDataRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          commit("SET_USER_DATA", doc.data());
        } else {
          console.error("No such document!");
        }
      })
      .catch((error) => console.error("Error getting document:", error));
  },

  setDefaultUserData({ commit }, user) {
    const userDataRef = firestore.collection("UsersData").doc(user.uid);
    const metadataRef = firestore.collection("metadata").doc(user.uid);

    userDataRef
      .set({
        uid: user.uid,
        email: user.authEmail,
        clinicUid: "",
        userName: "",
        fullName: "",
        isDisabled: false,
        isDeleted: false,
        timestamp: new Date().getTime(),
        noPrivateNotifications: false,
        passwordUpdatedOn: new Date().getTime()
      })
      .then(() => console.log("The document is added"))
      .catch((error) => {
        commit("SET_ERROR", error.message);
        console.error("Error writing document: ", error);
      });

    metadataRef.set({
      isDeleted: false,
      isDisabled: false
    });
  },

  updateUserAuthEmail(_, user) {
    firebaseAuth
      .signInWithEmailAndPassword(user.oldEmail, user.passwordConfirmation)
      .then((userCredential) => userCredential.user.updateEmail(user.newEmail));
  },

  updateUserData({ commit, dispatch }, payload) {
    const userDataRef = firestore.collection("UsersData").doc(payload.uid);

    userDataRef
      .update({ ...payload, updatedOn: new Date().getTime() }, { merge: true })
      .then(() => {
        console.log("The document is updated");
        dispatch("fetchUserData", payload.uid);
      })
      .catch((error) => {
        commit("SET_ERROR", error.message);
        console.error("Error writing document: ", error);
      });
  },

  async isPasswordOutdated({ commit }, payload) {
    return await firestore
      .collection("UsersData")
      .doc(payload.uid)
      .get()
      .then((doc) => {
        const passwordChangedDate = doc.data().updatedOn || doc.data().timestamp;

        const threeMonthsBeforeToday = new Date() - 1000 * 60 * 60 * 24 * 30 * 3;

        return passwordChangedDate < threeMonthsBeforeToday;
      })
      .catch((error) => {
        commit("SET_ERROR", error.message);
        console.error(error.message, error);
      });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
